@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400&display=swap");

.container-projects {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  h1 {
    font-family: "Josefin Sans", sans-serif;
    letter-spacing: 5px;
    font-weight: 300;
    font-size: 60px;
  }
  span {
    background: rgb(255, 130, 130);
    background: -moz-linear-gradient(
      left,
      rgba(255, 130, 130, 1) 0%,
      rgba(225, 120, 237, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 130, 130, 1) 0%,
      rgba(225, 120, 237, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 130, 130, 1) 0%,
      rgba(225, 120, 237, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8282', endColorstr='#e178ed',GradientType=1 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 50px !important;
    letter-spacing: 2px !important;
    text-align: center;
    margin-top: 0 !important;
   
  }
}
