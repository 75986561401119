@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400&display=swap");

.container-tech-skills {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  height: 90%;
  h1 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 300;
    font-size: 60px;
    margin-top: 80px;
    letter-spacing: 13px;
  }
  span {
    background: rgb(255, 130, 130);
    background: -moz-linear-gradient(
      left,
      rgba(255, 130, 130, 1) 0%,
      rgba(225, 120, 237, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 130, 130, 1) 0%,
      rgba(225, 120, 237, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 130, 130, 1) 0%,
      rgba(225, 120, 237, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8282', endColorstr='#e178ed',GradientType=1 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .tech-skills-component {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    flex-wrap: wrap;
    margin: 20px 0;
    .card-container {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: 10px;
      margin: 10px 0px;
      padding: 4% 1% 2% 1%;
      h2 {
        font-family: "Josefin Sans", sans-serif;
        font-weight: 400;
        font-size: 30px;
        text-align: center;
        background: rgb(255, 130, 130);
        background: -moz-linear-gradient(
          left,
          rgba(255, 130, 130, 1) 0%,
          rgba(225, 120, 237, 1) 100%
        );
        background: -webkit-linear-gradient(
          left,
          rgba(255, 130, 130, 1) 0%,
          rgba(225, 120, 237, 1) 100%
        );
        background: linear-gradient(
          to right,
          rgba(255, 130, 130, 1) 0%,
          rgba(225, 120, 237, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8282', endColorstr='#e178ed',GradientType=1 );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        font-family: "Josefin Sans", sans-serif;
        color: white;
        font-size: 18px;
        font-weight: 300;
        margin: 10px 0;
        letter-spacing: 5px;
      }
      svg {
        font-weight: 300;
      }
    }
    .glow-on-hover {
      width: 300px;
      height: 400px;
      border: none;
      outline: none;
      color: #fff;
      background: #111;
      cursor: pointer;
      position: relative;
      z-index: 0;
      border-radius: 10px;
    }

    .glow-on-hover:before {
      content: "";
      background: linear-gradient(
        45deg,
        rgba(255, 130, 130, 1) 0%,
        rgba(225, 120, 237, 1) 100%
      );
      // background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);;
      position: absolute;
      top: -10px;
      left: -10px;
      background-size: 400%;
      z-index: -1;
      filter: blur(4px);
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      border-radius: 10px;
    }

    .glow-on-hover:active {
      color: #000;
    }

    .glow-on-hover:active:after {
      background: transparent;
    }

    .glow-on-hover:hover:before {
      opacity: 1;
    }

    .glow-on-hover:after {
      z-index: -1;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #111;
      left: 0;
      top: 0;
      border-radius: 10px;
    }

    @keyframes glowing {
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 400% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 50px !important;
    letter-spacing: 2px !important;
  }
}
