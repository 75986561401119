@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400&display=swap");
.container-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  .image-project-carousel {
    width: 45%;
    margin: 0 5% 0 5%;
    border-radius: 15px;
  }
  .container-text-carousel {
    margin-right: 5%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    h2 {
      font-family: "Josefin Sans", sans-serif;
      letter-spacing: 5px;
      font-weight: 400;
      font-size: 35px;
      background: rgb(255, 130, 130);
      background: -moz-linear-gradient(
        left,
        rgba(255, 130, 130, 1) 0%,
        rgba(225, 120, 237, 1) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(255, 130, 130, 1) 0%,
        rgba(225, 120, 237, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(255, 130, 130, 1) 0%,
        rgba(225, 120, 237, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8282', endColorstr='#e178ed',GradientType=1 );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      font-family: "Josefin Sans", sans-serif;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 2px;
      text-align: left;
    }
    .btns-carousel {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 5%;
      a {
        background-color: black;
        font-family: "Josefin Sans", sans-serif;
        color: white;
        padding: 5%;
        margin-right: 3%;
        text-decoration: none;
        border-radius: 10px;
        width: 30%;
        text-align: center;
        font-size: 20px;
        height: 20px;
        display: flex;
            justify-content: center;
            align-items: center;
        &:hover {
          background: -moz-linear-gradient(
            left,
            rgba(255, 130, 130, 1) 0%,
            rgba(225, 120, 237, 1) 100%
          );
          background: -webkit-linear-gradient(
            left,
            rgba(255, 130, 130, 1) 0%,
            rgba(225, 120, 237, 1) 100%
          );
          background: linear-gradient(
            to right,
            rgba(255, 130, 130, 1) 0%,
            rgba(225, 120, 237, 1) 100%
          );
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container-carousel {
    // overflow-x: hidden;
    flex-flow: column;
    justify-content: flex-start !important;
    align-items: center !important;
    height: 100vh;
    .image-project-carousel {
      width: 70%;
      margin: 5%;
      border-radius: 0;
      display: none;
    }
    .container-text-carousel {
      margin-right: 40px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding: 0 5%;
      text-align: center;
      h2 {
        font-family: "Josefin Sans", sans-serif;
        letter-spacing: 5px;
        font-weight: 400;
        font-size: 30px;
        padding-left: 10%;}

        p {
          font-family: "Josefin Sans", sans-serif;
          font-size: 17px;
          font-weight: 400;
          letter-spacing: 2px;
          text-align: left;
          padding-left: 10%;
        }
        .btns-carousel {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 2%;
          padding-left: 10%;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center !important;
            height: 30px !important;
          }
        
      }
    }
  }
}
